.m0 {
    margin: 0px !important;
}

.m5 {
    margin: 5px !important;
}

.m10 {
    margin: 10px !important;
}

.m20 {
    margin: 20px !important;
}

.mr0 {
    margin-right: 0px !important;
}

.mr5 {
    margin-right: 5px !important;
}

.mr10 {
    margin-right: 10px !important;
}

.mr20 {
    margin-right: 20px !important;
}

.ml0 {
    margin-left: 0px !important;
}

.ml5 {
    margin-left: 5px !important;
}

.ml10 {
    margin-left: 10px !important;
}

.ml20 {
    margin-left: 20px !important;
}

.mt0 {
    margin-top: 0px !important;
}

.mt5 {
    margin-top: 5px !important;
}

.mt10 {
    margin-top: 10px !important;
}

.mt20 {
    margin-top: 20px !important;
}

.mb0 {
    margin-bottom: 0px !important;
}

.mb5 {
    margin-bottom: 5px !important;
}

.mb10 {
    margin-bottom: 10px !important;
}

.mb20 {
    margin-bottom: 20px !important;
}

.pd0 {
    padding: 0px !important;
}

.pd5 {
    padding: 5px !important;
}

.pd10 {
    padding: 10px !important;
}

.pd20 {
    padding: 20px !important;
}

.pl0 {
    padding-left: 0px !important;
}

.pl5 {
    padding-left: 5px !important;
}

.pl10 {
    padding-left: 10px !important;
}

.pl20 {
    padding-left: 20px !important;
}

.pr0 {
    padding-right: 0px !important;
}

.pdr5 {
    padding-right: 5px !important;
}

.pdr10 {
    padding-right: 10px !important;
}

.pdr20 {
    padding-right: 20px !important;
}

.pdt0 {
    padding-top: 0px !important;
}

.pdt5 {
    padding-top: 5px !important;
}

.pdt10 {
    padding-top: 10px !important;
}

.pdt20 {
    padding-top: 20px !important;
}

.pdb0 {
    padding-bottom: 0px !important;
}

.pdb5 {
    padding-bottom: 5px !important;
}

.pdb10 {
    padding-bottom: 10px !important;
}

.pdb20 {
    padding-bottom: 20px !important;
}

.wp20 {
    width: 20% !important;
}

.wp25 {
    width: 25% !important;
}

.wp50 {
    width: 50% !important;
}

.wp85 {
    width: 85% !important;
}

.wp95 {
    width: 95% !important;
}

.wp100 {
    width: 100% !important;
}

.wx20 {
    width: 20px !important;
}

.wx30 {
    width: 30px !important;
}

.wx50 {
    width: 50px !important;
}

.wx70 {
    width: 70px !important;
}

.wx80 {
    width: 80px !important;
}

.wx100 {
    width: 100px !important;
}

.hx30 {
    height: 30px !important;
}

.hx40 {
    height: 40px !important;
}

.hx50 {
    height: 50px !important;
}

.hx60 {
    height: 60px !important;
}

.hx100 {
    height: 100px !important;
}

.hp100 {
    height: 100% !important;
}

.fs11 {
    font-size: 11px !important;
}

.fs12 {
    font-size: 12px !important;
}

.fs13 {
    font-size: 13px !important;
}

.fs14 {
    font-size: 14px !important;
}

.fs15 {
    font-size: 15px !important;
}

.fs16 {
    font-size: 16px !important;
}

.fs17 {
    font-size: 17px !important;
}

.fs20 {
    font-size: 20px !important;
}

.red {
    color: red;
}

.blue {
    color: blue;
}

.orange {
    color: orange;
}

.green {
    color: green;
}

.black {
    color: black;
}

.white {
    color: white;
}

.gray {
    color: #ccc;
}

.fs-red {
    color: #e7586c !important;
}

.fs-blue {
    color: #005c98 !important;
}

.fs-black {
    color: #2f2f2f !important;
}

.fs-gray {
    color: #555 !important;
}

.fs-bold {
    font-weight: bold !important;
}

.fs-normal {
    font-weight: normal !important;
}

.txt-l {
    text-align: left !important;
}

.txt-r {
    text-align: right !important;
}

.txt-c {
    text-align: center !important;
}

.bdr-0 {
    border: none !important;
}

.bdr-t0 {
    border-top: none !important;
}

.bdr-l0 {
    border-left: none !important;
}

.bdr-r0 {
    border-right: none !important;
}

.bdr-b0 {
    border-bottom: none !important;
}

/* .hide {display:none !important;} */